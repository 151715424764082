import React from 'react';
import { Flex, Text, Box, Image } from '@chakra-ui/core';
import { CloseIcon } from '@chakra-ui/icons';
import { useLocation } from '@reach/router';

import { colors } from '@theme';
import { Button } from '@zeta';
import Link from './Link';
import { cloudfrontBaseUrl } from '../lib/consts';
import { GET_STARTED_URL } from '@lib/consts';

const Hamburger = props => {
  const { legal, homeNav, lightMode, toggleOpen } = props;
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <Flex
      direction="column"
      paddingX="24px"
      paddingY="24px"
      height="100%"
      justifyContent="space-between"
      zIndex={200}
    >
      <Flex flexDirection={'column'}>
        <Flex paddingBottom={'32px'} justify="space-between" direction="row">
          <Box display={'flex'} flexDirection="column" justify="center">
            <Link className="nav-link" noNewTab to="/" onClick={toggleOpen} timeout={0}>
              <Image
                loading="eager"
                alt=""
                src={
                  homeNav
                    ? `${cloudfrontBaseUrl}/logos/zeta-logo-lightmode.svg`
                    : lightMode
                    ? `${cloudfrontBaseUrl}/logos/zeta-logo-lightmode.svg`
                    : `${cloudfrontBaseUrl}/logos/zeta-logo-darkmode.svg`
                }
              />
            </Link>
          </Box>
          <CloseIcon onClick={toggleOpen} />
        </Flex>
        <Box paddingBottom={'24px'}>
          <Link timeout={100} onClick={toggleOpen} noNewTab to={'/'}>
            <Text
              fontSize="24px"
              color={colors.black}
              fontWeight={pathname.toString() === '/' ? '600' : '400'}
            >
              Home
            </Text>
          </Link>
        </Box>

        <Box paddingBottom={'24px'}>
          {/* <Link timeout={100} onClick={toggleOpen} noNewTab to={'/joint-bank-account'}> */}
          <Link timeout={0} noNewTab to={'/joint-bank-account'} onClick={toggleOpen}>
            <Text
              fontSize="24px"
              color={colors.black}
              fontWeight={pathname.includes('/joint-bank-account') ? '600' : '400'}
            >
              Joint Accounts
            </Text>
          </Link>
        </Box>

        <Box paddingBottom={'24px'}>
          <Link timeout={0} noNewTab to={'/family-bank-account'} onClick={toggleOpen}>
            <Text
              fontSize="24px"
              color={colors.black}
              fontWeight={pathname.includes('/family-bank-account') ? '600' : '400'}
            >
              Family Accounts
            </Text>
          </Link>
        </Box>

        <Box paddingBottom={'24px'}>
          <Link timeout={0} noNewTab to={'/personal-bank-account'} onClick={toggleOpen}>
            <Text
              fontSize="24px"
              color={colors.black}
              fontWeight={pathname.includes('/personal-bank-account') ? '600' : '400'}
            >
              Personal Accounts
            </Text>
          </Link>
        </Box>

        <Box paddingBottom={'24px'}>
          <Link timeout={100} onClick={toggleOpen} noNewTab to={'/zeta-plus'}>
            <Text
              fontSize="24px"
              color={colors.black}
              fontWeight={pathname.includes('/zeta-plus') ? '600' : '400'}
            >
              Zeta+
            </Text>
          </Link>
        </Box>

        <Box paddingBottom={'24px'}>
          <Link timeout={100} onClick={toggleOpen} noNewTab to={'/magazine'}>
            <Text
              fontSize="24px"
              color={colors.black}
              fontWeight={pathname.includes('/magazine') ? '600' : '400'}
            >
              Magazine
            </Text>
          </Link>
        </Box>

        <Box paddingBottom={'24px'}>
          <Link timeout={150} onClick={toggleOpen} href={'/help'}>
            <Text
              fontSize="24px"
              color={colors.black}
              fontWeight={pathname.includes('/help') ? '600' : '400'}
            >
              FAQ
            </Text>
          </Link>
        </Box>

        <Box paddingBottom={'24px'}>
          <Link timeout={150} onClick={toggleOpen} to={'/security'}>
            <Text
              fontSize="24px"
              color={colors.black}
              fontWeight={pathname.includes('/security') ? '600' : '400'}
            >
              Security
            </Text>
          </Link>
        </Box>
      </Flex>

      <Flex my={8} py={6} width="100%" justify="center" align="center">
        <Button
          text="Get started"
          variant="greenPea"
          large={true}
          style={{ width: '100%' }}
          onClick={() => {
            window.location.href = GET_STARTED_URL;
          }}
        />
      </Flex>
    </Flex>
  );
};

export default Hamburger;
