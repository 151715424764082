import React from 'react';
import { Flex, Text, Box, Image, Link } from '@chakra-ui/core';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import Lottie from 'react-lottie';
import { Button } from '@zeta';
import { APP_CONTENT_MOBILE_WIDTH, APP_CONTENT_WIDE_WIDTH } from '../../styles/sizes';
import ContentContainer from '../../components/ContentContainer';
import AsSeenIn from '../../components/home/asSeenIn';
import animation from '../../lotties/hero-1-animation-2.json';
import { cloudfrontBaseUrl } from '../../lib/consts';
import { colors } from '@theme';
import { GET_STARTED_URL } from '@lib/consts';

const TopSection = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
  };
  const GooglePlayLogo = `${cloudfrontBaseUrl}/homepage-24/home-googlePlay.svg`;
  const AppStoreLogo = `${cloudfrontBaseUrl}/homepage-24/home-appStore.svg`;

  return (
    <>
      <Box
        background={[
          `radial-gradient(100% 30% at 50% 100%, #ffffff 0%, transparent), radial-gradient(90% 90% at top left, #98D3B644 0%, transparent), radial-gradient(90% 90% at 110% -30%, #F2D02226 0%, transparent), radial-gradient(100% 90% at 80% 50%, #FAE4E4aa 0%, transparent), radial-gradient(110% 30% at -20% 50%, #6EDAF255 0%, transparent)`,
          `radial-gradient(100% 30% at 50% 100%, #ffffff 0%, transparent), radial-gradient(90% 90% at top left, #98D3B644 0%, transparent), radial-gradient(90% 90% at 110% -30%, #F2D02226 0%, transparent), radial-gradient(100% 90% at 100% 50%, #FAE4E4aa 0%, transparent), radial-gradient(110% 30% at -20% 50%, #6EDAF255 0%, transparent)`,
          `radial-gradient(90% 90% at top left, #98D3B644 0%, transparent), radial-gradient(90% 90% at top right, #F2D02226 0%, transparent), radial-gradient(40% 40% at 95% 85%, #FAE4E4dd 0%, transparent), radial-gradient(120% 80% at 10% 120%, #6EDAF277 0%, transparent)`,
          `radial-gradient(90% 90% at top left, #98D3B644 0%, transparent), radial-gradient(90% 90% at top right, #F2D02226 0%, transparent), radial-gradient(40% 40% at 95% 85%, #FAE4E4dd 0%, transparent), radial-gradient(120% 80% at 10% 120%, #6EDAF277 0%, transparent)`,
        ]}
        backgroundSize={'cover'}
        backgroundPosition={'center'}
        backgroundRepeat={'no-repeat'}
      >
        <ContentContainer
          width={'100%'}
          maxWidth={[
            APP_CONTENT_MOBILE_WIDTH,
            APP_CONTENT_MOBILE_WIDTH,
            APP_CONTENT_WIDE_WIDTH,
          ]}
          minWidth={['100%', '100%', '100%', APP_CONTENT_WIDE_WIDTH]}
        >
          <Flex
            direction="column"
            mb={[0, 0, 0, 0]}
            marginX={[0, 0, 0, 6]}
            px={[6, 6, 6, 0]}
            paddingTop={['5rem', '5rem', '5rem', 3]}
            paddingBottom={[3, 6, 12, 24]}
          >
            <Flex direction={['column', 'column', 'row']}>
              {/* Left side content */}
              <Flex
                paddingTop={['24px', '24px', '24px', '200px']}
                pr={[0, 0, 6]}
                direction="column"
                width={['100%', '100%', '55%', '55%']}
              >
                <Box marginTop={['12px', '12px', '64px', '24px']}>
                  <Text
                    textAlign={['center', 'center', 'left', 'left']}
                    fontSize={['40px', '40px', '40px', '60px']}
                    lineHeight={'1.2'}
                    fontWeight="600"
                    letterSpacing={'-0.78px'}
                    color={colors.darkGray}
                  >
                    Shared banking
                    <br />
                    for your family
                  </Text>
                  <br />
                  <Text
                    textAlign={['center', 'center', 'left', 'left']}
                    fontSize={['16px', '16px', '16px', '24px']}
                    lineHeight={['1.5', '1.5', '1.5', '1.7']}
                    fontWeight="400"
                    color={colors.grayGray900}
                  >
                    A modern-day account that supports
                    <br />
                    up to 5 members
                  </Text>
                </Box>

                <Flex
                  direction={['column', 'column', 'column', 'row']}
                  marginTop={['24px', '24px', '32px', '32px']}
                  paddingBottom={['52px', '64px', 0, 0]}
                  justifyContent={['center', 'center', 'left', 'left']}
                  width="100%"
                >
                  <Flex
                    justify={['center', 'center', 'left', 'left']}
                    marginRight={[0, 0, '25px', '25px']}
                  >
                    <Button
                      text={`Bank with us`}
                      variant="greenPea"
                      rightIcon={<ArrowForwardIcon style={{ marginBottom: '-2px' }} />}
                      style={{
                        fontSize: '18px',
                        fontWeight: '600',
                        lineHeight: '1.8',
                        paddingY: '24px',
                        paddingX: '32px',
                        color: colors.grayscale.gypsum,
                        borderRadius: '8px',
                        width: 'max-content',
                      }}
                      large
                      onClick={() => {
                        window.location.href = GET_STARTED_URL;
                      }}
                    />
                  </Flex>
                  <Flex
                    width={['100%', '100%', 'max-content', 'max-content']}
                    maxWidth={['100%', '100%', '440px', '50%']}
                    alignItems="center"
                    justifyContent={['center', 'center', 'flex-start', 'flex-start']}
                    paddingTop={['25px', '25px', '25px', 0]}
                  >
                    <Link
                      href="https://play.google.com/store/apps/details?id=com.askzeta.aZetaFi&hl=en_US&gl=US"
                      rel="noopener noreferrer"
                      target="_blank"
                      height="100%"
                      width={['50%', '60%', '100%', '100%']}
                      paddingRight={['2px', '12px', '24px', '24px']}
                      display={'flex'}
                      justifyContent={'right'}
                    >
                      <Image src={GooglePlayLogo} />
                    </Link>
                    <Link
                      href="https://apps.apple.com/us/app/zeta-joint-card/id1493011848"
                      rel="noopener noreferrer"
                      target="_blank"
                      height="100%"
                      width={['50%', '60%', '100%', '100%']}
                      paddingLeft={['2px', '12px', '0px', '0px']}
                      display={'flex'}
                      justifyContent={'left'}
                    >
                      <Image src={AppStoreLogo} />
                    </Link>
                  </Flex>
                </Flex>

                <Box
                  display={['none', 'none', 'flex', 'flex']}
                  flexDirection="row"
                  justifyContent="space-between"
                  width={['75%', '75%', '95%', '90%']}
                  marginTop={['62px', '62px', '15%', '15%']}
                  marginBottom={0}
                  paddingBottom={['0px', '0px', '48px', '56px']}
                >
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Text
                      fontSize={['24px', '32px', '32px', '39px']}
                      fontWeight="600"
                      lineHeight={1.2}
                      letterSpacing={'-0.78px'}
                      color={colors.darkGray}
                    >
                      4.5 stars
                    </Text>
                    <Text
                      fontSize={['12px', '12px', '12px', '14px']}
                      marginTop={[0, 0, '-2px']}
                    >
                      App store rating
                    </Text>
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Text
                      fontSize={['24px', '32px', '32px', '39px']}
                      fontWeight="600"
                      lineHeight={1.2}
                      letterSpacing={'-0.78px'}
                      color={colors.darkGray}
                    >
                      $90M
                    </Text>

                    <Text
                      fontSize={['12px', '12px', '12px', '14px']}
                      marginTop={[0, 0, '-2px']}
                    >
                      Saved on Zeta
                    </Text>
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Text
                      fontSize={['24px', '32px', '32px', '39px']}
                      fontWeight="600"
                      lineHeight={1.2}
                      letterSpacing={'-0.78px'}
                      color={colors.darkGray}
                    >
                      100,000+
                    </Text>
                    <Text
                      fontSize={['12px', '12px', '12px', '14px']}
                      marginTop={[0, 0, '-2px']}
                    >
                      Members signed up
                    </Text>
                  </Box>
                </Box>
              </Flex>

              {/* Right side animation */}
              <Flex
                width={'auto'}
                maxWidth={['100%', '100%', '50%', '42%']}
                marginRight={[0, 0, '-20px', '-20px']}
                marginLeft={[0, '20px', '20px', '20px']}
                paddingTop={[0]}
                justifyContent={'flex-end'}
                flexDirection={'column'}
                alignItems={['center', 'center', 'flex-start', 'flex-start']}
              >
                <Box
                  display="flex"
                  width={['100%', '80%', '125%', '130%']}
                  marginLeft={[0, 0, '-10px', '-24px']}
                >
                  <Lottie
                    style={{
                      cursor: 'auto',
                      backgroundColor: 'transparent',
                    }}
                    isClickToPauseDisabled={true}
                    options={defaultOptions}
                    width={700}
                  />
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </ContentContainer>

        <Box
          display={['flex', 'flex', 'none', 'none']}
          flexDirection={['column', 'row']}
          justifyContent={['space-between', 'space-evenly']}
          width={'100%'}
          mt={'0px'}
          paddingY={6}
          alignItems="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            paddingBottom="36px"
          >
            <Text
              fontSize={'39px'}
              fontWeight={'600'}
              lineHeight={1.2}
              letterSpacing={'-0.78px'}
              color={colors.darkGray}
            >
              4.5 stars
            </Text>

            <Text
              fontSize={'14px'}
              lineHeight={1}
              fontWeight={'400'}
              color={colors.darkGray}
            >
              App store rating
            </Text>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            paddingBottom="36px"
          >
            <Text
              fontSize={'39px'}
              fontWeight={'600'}
              lineHeight={1.2}
              letterSpacing={'-0.78px'}
              color={colors.darkGray}
            >
              $90M
            </Text>
            <Text
              fontSize={'14px'}
              lineHeight={1}
              fontWeight={'400'}
              color={colors.darkGray}
            >
              Saved on Zeta
            </Text>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            paddingBottom="36px"
          >
            <Text
              fontSize={'39px'}
              fontWeight={'600'}
              lineHeight={1.2}
              letterSpacing={'-0.78px'}
              color={colors.darkGray}
            >
              100,000+
            </Text>
            <Text
              fontSize={'14px'}
              lineHeight={1}
              fontWeight={'400'}
              color={colors.darkGray}
            >
              Members signed up
            </Text>
          </Box>
        </Box>

        <AsSeenIn />
      </Box>
    </>
  );
};

export default TopSection;
